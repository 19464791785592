import React from "react"
import featureimage from "../images/featureimage.png"
import createicon from "../images/createicon.svg"
import zepicon from "../images/zepicon.svg"
import charticon from "../images/charticon.svg"
import messageicon from "../images/messageicon.svg"

const features = [
  {
    name: "Create high fidelity cases",
    description:
      "Clinicians and medical educators can easily author and create fully interactive clinical cases. Designed by experts in the field of medical education, our solution has proven benefits for fostering clinical reasoning.",
    icon: createicon,
  },
  {
    name: "Personal study tool",
    description:
      "Backed by an extensive knowledge database, learners can easily bookmark landmark articles and teaching points referenced from completed clinical cases.",
    icon: zepicon,
  },
  {
    name: "Manage your learning group with reports",
    description:
      "Measure what matters with ADITUM’s easy-to-use reports. You can extract the most important statistics regarding learners’ performance and use it to tailor your teaching according to their needs.",
    icon: charticon,
  },
  {
    name: "Interactive discussion",
    description:
      "Our software allows learning groups to discuss around cases and benefit from the collective intelligence of a group.",
    icon: messageicon,
  },
]
export default function FeatureSection() {
  return (
    <div className="overflow-hidden bg-white py-16 sm:py-20">
      <div className="mx-auto max-w-7xl  flex flex-col items-center px-6 lg:px-8">
        <div className="  flex-col items-center flex max-w-xl text-center">
          <p className="text-base font-semibold leading-7 text-primary-600">
            Features
          </p>
          <h1 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
            A comprehensive medical education software{" "}
          </h1>
          <p className="mt-6 text-lg  pb-6 ">
            ADITUM is an end-to-end solution for medical education.
          </p>
          <p>
            <strong>Clinicians</strong> can easily author and create fully
            interactive clinical cases.
          </p>
          <p>
            <strong>Learners</strong> can use the app to foster clinical
            reasoning and knowledge retention.
          </p>
          <p>
            <strong>Institutions</strong> can implement it to complement
            conventional learning methods while tracking student performance.
          </p>
        </div>

        <div className=" max-w-2xl py-16">
          <img
            src={featureimage}
            alt="Product screenshot"
            className=" w-full h-auto"
            width={2432}
            height={1442}
          />
        </div>
        <div className="mx-auto  max-w-7xl ">
          <div className="mx-auto max-w-4xl  w-full  lg:max-w-7xl ">
            <dl className="grid max-w-4xl grid-cols-1   gap-y-10 items-start   lg:max-w-none lg:grid-cols-4 lg:gap-y-10">
              {features.map(feature => (
                <div
                  key={feature.name}
                  className=" flex flex-col space-y-4   md:pl-8"
                >
                  <img src={feature.icon} alt="icon" className="h-12 w-12" />

                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
