import { useState } from "react"
import { ChevronRightIcon } from "@heroicons/react/20/solid"
import React from "react"
import herophonesm from "../images/herophonesm.png"
import herolaptop from "../images/herolaptop.png"
import herobackground from "../images/herobackground.png"
const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Marketplace", href: "#" },
  { name: "Company", href: "#" },
]

export default function HeroSection() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="overflow-hidden py-16 sm:py-20">
      <div className="mx-auto relative max-w-7xl  ">
        <div className="  items-start justify-end  object-contain  flex flex-col absolute w-full h-full z-10">
          <img src={herobackground} alt="Aditum" />
        </div>
        <div className="mx-auto grid max-w-7xl grid-cols-1 px-10  z-50  gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
          <div className="mx-auto z-30 max-w-2xl lg:mx-0 lg:flex-auto pt-32">
            <div className="flex">
              <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                <div className="  px-2 rounded-full border border-blue-500">
                  <span className=" text-sm text-primary-600">New!</span>
                </div>

                <a href="#" className="flex items-center gap-x-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  Medical education platform
                  <ChevronRightIcon
                    className="-mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
            <h1 className="mt-10 max-w-lg text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              Medical cases in your pocket made fun.
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Designed by healthcare professionals. ADITUM offers you a modern
              solution for medical education at your institution.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                Get started
              </a>
              <a
                href="#"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <img
            src={herolaptop}
            alt="Product screenshot"
            className="w-[48rem]   z-30  sm:mt-20  overflow-x-hidden   max-w-none rounded-xl   sm:w-[57rem] md:-ml-4 lg:ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  )
}
