import React from "react"

const OurmissionSection = () => {
  return (
    <div className="mx-auto max-w-7xl  px-6 lg:px-8">
      <div className="  ">
        <div className="mx-auto flex flex-col space-y-6     py-20 text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
            Our mission
          </h1>
          <p className="mt-6  ">
            Aditum is a simple web & mobile app based medical learning platform
            bridging the gap between interactive simulation, medical social
            networks and traditional medical knowledge platform.
          </p>
          <p>
            It rigorously integrates peer-reviewed cases to provide a
            cost-effective LMS solution to accredited Continuing Medical
            Education organizations and universities worldwide thanks to its
            streamlined open-access content creation portal and cutting-edge
            student performance tracking features.{" "}
          </p>
          <p>
            Through in-depth real-time feedback based on advanced patient
            simulation and Evidence Based Medicine combined with built-in peer
            group discussions, Aditum maximizes learning for every medical
            student.{" "}
          </p>
          <p>
            With its experienced governance team and a clear roadmap for
            improvements including AI, Aditum positions itself as a trusted
            long-term partner for medical learning organizations.
          </p>
        </div>
      </div>
    </div>
  )
}

export default OurmissionSection
