import React from "react"
export default function SuuportedbySection() {
  return (
    <div className=" py-2 sm:py-2 z-50">
      <div className="mx-auto max-w-7xl px-6 flex flex-col items-center  lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          Supported by
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y-10 sm:max-w-xl   lg:mx-0  ">
          <img
            className=" max-h-16 w-full object-contain "
            src="https://upload.wikimedia.org/wikipedia/en/4/4b/Universite_de_Montreal_logo.svg"
            alt="Transistor"
            height={48}
          />
          <img
            placeholder="blurred"
            layout="fixed"
            className=" max-h-16 w-full object-contain "
            src="https://identityguide.hms.harvard.edu/sites/projects.iq.harvard.edu/files/styles/os_files_large/public/hmsidentityguide/files/hms_logo_final_rgb_0.png?m=1580238886&itok=bg7O1O5n"
            alt="Reform"
            height={48}
          />
        </div>
      </div>
    </div>
  )
}
