import React from "react"
import trialPhoto from "../images/trialphoto2.png"
import appstorewhite from "../images/appstorewhite.svg"
import playstorewhite from "../images/playstorewhite.svg"
const StartTrailSection = () => {
  return (
    <div className="overflow-hidden     py-24 sm:py-32">
      <div className="mx-auto max-w-7xl  px-6 lg:px-8">
        <div className="bg-primary-800 rounded-3xl shadow-lg w-full px-10 py-4  gap-2  md:gap-4 grid grid-cols-1 md:grid-cols-2">
          <div className=" flex flex-col space-y-4 items-start justify-center ">
            <span className=" text-2xl sm:text-4xl font-semibold text-white">
              Start your free trial
            </span>
            <span className=" text-lg text-white">
              Clinical teaching made easy.
            </span>
            <div className=" flex flex-row items-center gap-2 py-6">
              <img src={appstorewhite} alt="" className="w-40" />
              <img src={playstorewhite} alt="" className="w-40" />
            </div>
          </div>
          <div className=" flex flex-col  w-full  -mb-4 items-end">
            <img src={trialPhoto} alt="trial" className="     " />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartTrailSection
