import React from "react"
import newsLetter from "../images/newsletter.png"
export default function NewsletterSection() {
  return (
    <div className=" py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-7xl  px-6 lg:px-8">
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-2 lg:gap-8 lg:px-8">
          <form className="w-full max-w-md  space-y-4 flex flex-col justify-center   lg:pt-2">
            <h1 className=" text-4xl font-semibold">
              Be the first to know when we launch
            </h1>
            <span className=" text-gray-600 text-sm">
              We’re still building. Subscribe for updates and 20% off when we
              launch. No spam, we promise!
            </span>
            <div className="flex gap-x-4">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto border rounded-md border-1 px-3.5 py-2  shadow-sm ring-1 ring-inset ring-white/10 placeholder:/75 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-primary-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-600  "
              >
                Subscribe
              </button>
            </div>
            <p className="mt-4 text-sm leading-6 text-gray-300">
              We care about your data. Read our{" "}
              <a href="#" className="font-semibold  hover:text-primary-500">
                privacy&nbsp;policy
              </a>
              .
            </p>
          </form>
          <div className="max-w-xl text-3xl font-bold tracking-tight  sm:text-4xl ">
            <img src={newsLetter} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
