import React from "react"
import HeroSection from "../components/heroSection"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SupportedbySection from "../components/supportedbySection"
import FeatureSection from "../components/featureSection"
import StartTrailSection from "../components/startTrailSection"
import OurmissionSection from "../components/ourmissionSection"
import NewsletterSection from "../components/newsletterSection"
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroSection />
    <SupportedbySection />
    <FeatureSection />
    <StartTrailSection />
    <OurmissionSection />
    <NewsletterSection />
  </Layout>
)

export default IndexPage
